/**
 * PQ BE
 * A be service for  pq
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

export interface Message {
    id?: string;

    content?: Array<models.MessageContent>;

    timestamp?: string;

    role?: Message.RoleEnum;

    runDetails?: any;
}
export namespace Message {
    export enum RoleEnum {
        User = <any> 'user',
        Assistant = <any> 'assistant'
    }
}
