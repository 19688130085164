import React, { useState, useEffect, useRef } from 'react';
import PrefixTrie from '../datastructures/prefix_trie';

const SpotlightSearchComponent: React.FC<{
    placeholder: string,
    searchValues: string[],
    show: boolean,
    handleSearchValueClicked: (value: string) => void,
}> = ({ placeholder, searchValues, show, handleSearchValueClicked }) => {
    const tickerPrefixTrie = useRef(new PrefixTrie(searchValues)).current;
    const [inputValue, setInputValue] = useState('');
    const [matches, setMatches] = useState<string[]>([]);
    const debounceTimeout = useRef<number | undefined>(undefined);
    const DEBOUNCE_DELAY = 300; // milliseconds

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = window.setTimeout(() => {
            if (value === '') {
                setMatches([]);
                return;
            }
            const matchedValues = tickerPrefixTrie.getMatches(value);
            setMatches(matchedValues);
        }, DEBOUNCE_DELAY);
    };

    // Listen for "Command + Space" shortcut to focus on the search bar
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.metaKey && e.key === ' ') {
                e.preventDefault();
                document.getElementById('spotlight-search-input')?.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // Only render when there are no conversations
    if (!show) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            boxSizing: 'border-box',
            overflow: 'hidden',
        }}>
            <div style={styles.searchBox}>
                <input
                    id="spotlight-search-input"
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    placeholder={placeholder}
                    style={styles.input}
                />
                {matches.length > 0 && (
                    <div style={styles.matchesContainer}>
                        {matches.map((match, index) => (
                            <div key={index} style={styles.matchItem} onClick={() => {
                                console.log("Selected match: ", match);
                                handleSearchValueClicked(match);
                            }}>
                                {match}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

// Updated styles for overlay, search box, and matches
const styles = {
    searchBox: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '10px 10px',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
        alignItems: 'center',
        width: '60%',
        maxWidth: '600px',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        // marginBottom: '10px',
        boxSizing: 'border-box' as const,
    },
    matchesContainer: {
        maxHeight: '200px',
        overflowY: 'auto' as const,
        borderTop: '1px solid #eee',
        paddingTop: '10px',
    },
    matchItem: {
        padding: '8px',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
    },
};

export default SpotlightSearchComponent;
