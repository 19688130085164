import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SignInResponse } from "../api/models/SignInResponse";
import { getUser, refreshTokens, signIn } from "../api/thunks/users";
import { User } from "../api/models/User";

export interface UserState {
    user: User | undefined;
    credentials: SignInResponse | undefined;
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: undefined,
        credentials: undefined,
    } as UserState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        setCredentials: (state, action: PayloadAction<SignInResponse>) => {
            state.credentials = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signIn.fulfilled, (state, action) => {
            state.credentials = action.payload.tokens;
            state.user = action.payload.user;
        });

        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload;
        })

        builder.addCase(refreshTokens.fulfilled, (state, action) => {
            const { payload } = action;
            const { accessToken, refreshToken } = payload;
            if (accessToken && accessToken.length > 0) {
                state!.credentials!.accessToken = accessToken;
            }

            if (refreshToken && refreshToken.length > 0) {
                state!.credentials!.refreshToken = refreshToken;
            }
        })
    }
});

export const { setUser, setCredentials } = userSlice.actions;
export default userSlice.reducer;
