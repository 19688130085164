import axios from "axios";
import { Transcript } from "../models/Transcript";
import { setNotification } from "../../store/notifications-reducer";

// Async function to get the earnings transcript
export const getEarningsTranscript = async (
    ticker : string, fiscalDateEnding : string,
    accessToken: string,
    dispatch: (action: any) => void
): Promise<Transcript> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/stocks/${ticker}/transcripts/${fiscalDateEnding}`, {
        headers: { 'Authorization': accessToken }
    });

    if (response.status !== 200) {
        const err: any = new Error('Failed to get earnings transcript');
        err.httpCode = response.status;
        if (response.status === 403 && response?.data?.code === 1) {
            dispatch(setNotification({
                message: 'You do not have access to this earnings report. Please upgrade to premium to access all earnings.',
                type: 'error'
            }));
        }
        throw err;
    }

    return response.data as Transcript;
};
