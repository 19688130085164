import { useState } from "react"
import { EarningsReportComponent } from "./earnings-report-component"
import { MainBackground } from "./main-background"
import { TranscriptComponent } from "./transcript-component"
import { Transcript } from "../api/models/Transcript"
import { EarningsReport } from "../api/models/EarningsReport"
import { Conversation } from "../api/models/Conversation"
import { Message } from "../api/models/Message"
import { FileCitation } from "../api/models/FileCitation"
import { AppleEarnings20240630, AppleTranscript20240630 } from "../sample_assets/appl_2024-06-30"
import { LlmConvoComponent } from "./llm-convo-component"
import { useNavigate } from "react-router-dom"
import { funnelRoutes, navigationRoutes } from "../constants/constants"
import { EarningsDate } from "../api/models/EarningsDate"

export const TryIt = () => {
    document.title = "Try It | PocketQuant";
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);
    const openModal = () => setIsModalOpen(true);
    const navigate = useNavigate();

    const [message, setMessage] = useState<string>("");
    let conversation: Conversation = {
        "id": "f206e472-328b-49f7-8ef1-047f812e4ec6",
        "messages": [
            {
                "content": [
                    {
                        "fileCitations": [],
                        "text": "what geographies had the strongest revenue growth in the most recent quarter?"
                    }
                ],
                "id": "msg_OYmUX2DRkVpZyadipIbqet74",
                "role": Message.RoleEnum.User,
                "timestamp": "2024-09-19T04:54:45Z"
            },
            {
                "content": [
                    {
                        "fileCitations": [
                            {
                                "endIndex": 451,
                                "entityType": FileCitation.TypeEnum.Transcript,
                                "fileId": "file-jtAm7WuT2ajAqwTNCJW1o0SY",
                                "fileName": "AAPL-transcript-2024-06-30.json",
                                "fiscalDateEnding": "2024-06-30",
                                "startIndex": 438,
                                "text": "【16:4†source】"
                            },
                            {
                                "endIndex": 464,
                                "entityType": FileCitation.TypeEnum.Transcript,
                                "fileId": "file-jtAm7WuT2ajAqwTNCJW1o0SY",
                                "fileName": "AAPL-transcript-2024-06-30.json",
                                "fiscalDateEnding": "2024-06-30",
                                "startIndex": 451,
                                "text": "【16:6†source】"
                            }
                        ],
                        "text": "In Q2 2024, revenue growth was the strongest in the following geographies:\n\n- **India**: Achieved a new all-time revenue record.\n- **Latin America**: Set June quarter records.\n- **Middle East and Africa**: Set June quarter records.\n- **Indonesia**: Set June quarter records.\n- **Philippines**: Set June quarter records.\n- **Italy**: Set June quarter records.\n- **Netherlands**: Set June quarter records.\n- **UK**: Set June quarter records【16:4†source】【16:6†source】."
                    }
                ],
                "id": "msg_EKrOpVkxzIx2p5X5kDwRPoBs",
                "role": Message.RoleEnum.Assistant,
                "timestamp": "2024-09-19T05:19:31Z"
            }
        ],
        "name": "AAPL",
        "ticker": "AAPL"
    };
    let earningsDates: EarningsDate[] = [
        {
            "date": "2024-06-30",
            "userHasAccess": true
        },
        {
            "date": "2024-03-31",
            "userHasAccess": false
        },
        {
            "date": "2024-03-30",
            "userHasAccess": false
        },
        {
            "date": "2023-12-31",
            "userHasAccess": false
        },
        {
            "date": "2023-12-30",
            "userHasAccess": false
        },
        {
            "date": "2023-09-30",
            "userHasAccess": false
        },
        {
            "date": "2023-07-01",
            "userHasAccess": false
        },
        {
            "date": "2023-06-30",
            "userHasAccess": false
        },
        {
            "date": "2023-04-01",
            "userHasAccess": false
        },
        {
            "date": "2023-03-31",
            "userHasAccess": false
        },
        {
            "date": "2022-12-31",
            "userHasAccess": false
        },
        {
            "date": "2022-09-30",
            "userHasAccess": false
        },
        {
            "date": "2022-09-24",
            "userHasAccess": false
        },
        {
            "date": "2022-06-30",
            "userHasAccess": false
        },
        {
            "date": "2022-06-25",
            "userHasAccess": false
        },
        {
            "date": "2022-03-31",
            "userHasAccess": false
        },
        {
            "date": "2022-03-26",
            "userHasAccess": false
        },
        {
            "date": "2021-12-31",
            "userHasAccess": false
        },
        {
            "date": "2021-12-25",
            "userHasAccess": false
        },
        {
            "date": "2021-09-30",
            "userHasAccess": false
        },
        {
            "date": "2021-09-25",
            "userHasAccess": false
        },
        {
            "date": "2021-06-30",
            "userHasAccess": false
        },
        {
            "date": "2021-06-26",
            "userHasAccess": false
        },
        {
            "date": "2021-03-31",
            "userHasAccess": false
        },
        {
            "date": "2021-03-27",
            "userHasAccess": false
        },
        {
            "date": "2020-12-31",
            "userHasAccess": false
        },
        {
            "date": "2020-12-26",
            "userHasAccess": false
        },
        {
            "date": "2020-09-30",
            "userHasAccess": false
        },
        {
            "date": "2020-09-26",
            "userHasAccess": false
        },
        {
            "date": "2020-06-30",
            "userHasAccess": false
        },
        {
            "date": "2020-06-27",
            "userHasAccess": false
        },
        {
            "date": "2020-03-31",
            "userHasAccess": false
        },
        {
            "date": "2020-03-28",
            "userHasAccess": false
        },
        {
            "date": "2019-12-31",
            "userHasAccess": false
        },
        {
            "date": "2019-12-28",
            "userHasAccess": false
        },
        {
            "date": "2019-09-30",
            "userHasAccess": false
        },
        {
            "date": "2019-09-28",
            "userHasAccess": false
        },
        {
            "date": "2019-06-30",
            "userHasAccess": false
        },
        {
            "date": "2019-06-29",
            "userHasAccess": false
        },
        {
            "date": "2019-03-31",
            "userHasAccess": false
        },
        {
            "date": "2019-03-30",
            "userHasAccess": false
        },
        {
            "date": "2018-12-31",
            "userHasAccess": false
        },
        {
            "date": "2018-12-29",
            "userHasAccess": false
        },
        {
            "date": "2018-09-30",
            "userHasAccess": false
        },
        {
            "date": "2018-09-29",
            "userHasAccess": false
        },
        {
            "date": "2018-06-30",
            "userHasAccess": false
        },
        {
            "date": "2018-03-31",
            "userHasAccess": false
        },
        {
            "date": "2017-12-31",
            "userHasAccess": false
        },
        {
            "date": "2017-12-30",
            "userHasAccess": false
        },
        {
            "date": "2017-09-30",
            "userHasAccess": false
        },
        {
            "date": "2017-07-01",
            "userHasAccess": false
        },
        {
            "date": "2017-06-30",
            "userHasAccess": false
        },
        {
            "date": "2017-04-01",
            "userHasAccess": false
        },
        {
            "date": "2017-03-31",
            "userHasAccess": false
        },
        {
            "date": "2016-12-31",
            "userHasAccess": false
        },
        {
            "date": "2016-09-30",
            "userHasAccess": false
        },
        {
            "date": "2016-09-24",
            "userHasAccess": false
        },
        {
            "date": "2016-06-30",
            "userHasAccess": false
        },
        {
            "date": "2016-06-25",
            "userHasAccess": false
        },
        {
            "date": "2016-03-31",
            "userHasAccess": false
        },
        {
            "date": "2016-03-26",
            "userHasAccess": false
        },
        {
            "date": "2015-12-31",
            "userHasAccess": false
        },
        {
            "date": "2015-12-26",
            "userHasAccess": false
        },
        {
            "date": "2015-09-30",
            "userHasAccess": false
        },
        {
            "date": "2015-09-26",
            "userHasAccess": false
        },
        {
            "date": "2015-06-30",
            "userHasAccess": false
        },
        {
            "date": "2015-06-27",
            "userHasAccess": false
        },
        {
            "date": "2015-03-31",
            "userHasAccess": false
        },
        {
            "date": "2015-03-28",
            "userHasAccess": false
        },
        {
            "date": "2014-12-31",
            "userHasAccess": false
        },
        {
            "date": "2014-12-27",
            "userHasAccess": false
        },
        {
            "date": "2014-09-30",
            "userHasAccess": false
        },
        {
            "date": "2014-09-27",
            "userHasAccess": false
        },
        {
            "date": "2014-06-30",
            "userHasAccess": false
        },
        {
            "date": "2014-06-28",
            "userHasAccess": false
        },
        {
            "date": "2014-03-31",
            "userHasAccess": false
        },
        {
            "date": "2014-03-29",
            "userHasAccess": false
        },
        {
            "date": "2013-12-31",
            "userHasAccess": false
        },
        {
            "date": "2013-09-30",
            "userHasAccess": false
        },
        {
            "date": "2013-06-30",
            "userHasAccess": false
        },
        {
            "date": "2013-03-31",
            "userHasAccess": false
        },
        {
            "date": "2012-12-31",
            "userHasAccess": false
        },
        {
            "date": "2012-09-30",
            "userHasAccess": false
        },
        {
            "date": "2012-06-30",
            "userHasAccess": false
        },
        {
            "date": "2012-03-31",
            "userHasAccess": false
        },
        {
            "date": "2011-12-31",
            "userHasAccess": false
        },
        {
            "date": "2011-09-30",
            "userHasAccess": false
        },
        {
            "date": "2011-06-30",
            "userHasAccess": false
        },
        {
            "date": "2011-03-31",
            "userHasAccess": false
        },
        {
            "date": "2010-12-31",
            "userHasAccess": false
        },
        {
            "date": "2010-09-30",
            "userHasAccess": false
        },
        {
            "date": "2010-06-30",
            "userHasAccess": false
        },
        {
            "date": "2010-03-31",
            "userHasAccess": false
        },
        {
            "date": "2009-12-31",
            "userHasAccess": false
        },
        {
            "date": "2009-09-30",
            "userHasAccess": false
        }
    ];
    let transcriptDates : EarningsDate[] = [
        {
            "date": "2024-09-30",
            "userHasAccess": false
        },
        {
            "date": "2024-06-30",
            "userHasAccess": true
        },
        {
            "date": "2024-03-31",
            "userHasAccess": false
        },
        {
            "date": "2023-12-31",
            "userHasAccess": false
        },
        {
            "date": "2023-09-30",
            "userHasAccess": false
        },
        {
            "date": "2023-06-30",
            "userHasAccess": false
        },
        {
            "date": "2023-03-31",
            "userHasAccess": false
        },
        {
            "date": "2022-12-31",
            "userHasAccess": false
        },
        {
            "date": "2022-06-30",
            "userHasAccess": false
        },
        {
            "date": "2022-03-31",
            "userHasAccess": false
        },
        {
            "date": "2021-09-30",
            "userHasAccess": false
        }
    ]
    const localNavigation = funnelRoutes.filter(x => x.href !== '/try-it');

    const getCitationTextDetails = async (conversationId: string, messageId: string, fileId: string): Promise<string> => {
        return `Thank you, Suhasini. Good afternoon, everyone, and thanks for joining the call. Today, Apple is reporting revenue of $90.8 billion and an EPS record of $1.53 for the March quarter. We set revenue records in more than a dozen countries and regions. These include, among others, March quarter records in Latin-America and the Middle East, as well as Canada, India, Spain and Turkey. We also achieved an all-time revenue record in Indonesia, one of the many markets where we continue to see so much potential.In services, we set an all-time revenue record, up 14% over the past year.`
    }

    const sendMessage = async (msg: string): Promise<boolean> => {
        // TODO: signup popup with Google & Facebook buttons
        openModal();
        return true;
    }

    return (
        <>
            <header className="absolute inset-x-0 p-6 lg:px-8 top-0 z-50">
                <nav className="flex items-center justify-center" aria-label="Global">
                    <div className="hidden lg:flex lg:gap-x-12 mb-10">
                        {localNavigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                </nav>
            </header>
            <MainBackground convos={[]} setConvos={(val: Conversation[]) => { }} currConvo={conversation} supportedTickers={['APPL']} showMenu={false}>
                <div className="flex flex-col md:flex-row mt-6 h-screen pl-8 pr-8" style={{ height: '85vh' }}>
                    <div className="hidden md:block md:w-1/3">
                        <EarningsReportComponent currentFiscalDateEnding={earningsDates[0].date} earnings={AppleEarnings20240630} earningsDates={earningsDates} setCurrentFiscalDateEnding={() => { }} />
                    </div>
                    <div className="md:w-1/3">
                        <LlmConvoComponent convo={conversation} getCitationTextDetails={getCitationTextDetails} message={message} setMessage={setMessage} sendMessage={sendMessage} />
                    </div>
                    <div className="hidden md:block md:w-1/3">
                        <TranscriptComponent currentFiscalDateEnding={earningsDates[0].date} earningsDates={transcriptDates} setCurrentFiscalDateEnding={(date: string) => { }} transcript={AppleTranscript20240630} />
                    </div>
                </div>
                {isModalOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
                            {/* Close button */}
                            <button
                                className="absolute top-3 right-3 text-gray-500"
                                onClick={closeModal}
                            >
                                &times;
                            </button>

                            {/* Modal Content */}
                            <h2 className="text-xl font-semibold mb-4 justify-center">Login/Sign Up</h2>
                            {/* Login Button */}
                            <div className="flex justify-around">
                                <button
                                    onClick={() => navigate('/login')}
                                    className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300"
                                >
                                    Login
                                </button>
                                {/* Signup Button */}
                                <button
                                    onClick={() => navigate('/signup')}
                                    className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300"
                                >
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </MainBackground>
        </>
    )
}