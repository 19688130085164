class TrieNode {
    children: { [key: string]: TrieNode };
    isEndOfWord: boolean;
    constructor() {
        this.children = {};
        this.isEndOfWord = false;
    }
}

class PrefixTrie {
    root: TrieNode;
    buildTrie(values: string[]) {
        for (let value of values) {
            let node = this.root;
            for (let char of value) {
                if (!node.children[char]) {
                    node.children[char] = new TrieNode();
                }
                node = node.children[char];
            }
            node.isEndOfWord = true;
        }
    }

    getMatches(prefix: string): string[] {
        let node = this.root;
        for (let char of prefix) {
            if (!node.children[char]) {
                return [];
            }
            node = node.children[char];
        }
        return this.getAllWords(node, prefix);
    }

    getAllWords(node: TrieNode, prefix: string): string[] {
        let words: string[] = [];
        if (node.isEndOfWord) {
            words.push(prefix);
        }
        for (let char in node.children) {
            words.push(...this.getAllWords(node.children[char], prefix + char));
        }
        return words
    }

    constructor(values: string[]) {
        this.root = new TrieNode();
        this.buildTrie(values);
    }

}

export default PrefixTrie;