import './index.css';
import LoginComponent from './components/login-component';
import { DashboardComponent } from './components/dashboard-component';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './components/error-page';
import { SignUpComponent } from './components/signup-page-component';
import { ForgotPasswordComponent } from './components/forgot-password-component';
import { TermsOfServiceComponent } from './components/terms-of-service-component';
import { ProfileComponent } from './components/profile-component';
import SplashPage from './components/splash-page';
import { CompanyComponent } from './components/company-component';
import { FeaturesComponent } from './components/features-component';
import { ChangelogComponent } from './components/change-log-component';
import { PrivacyPolicyComponent } from './components/privacy-policy-component';
import { MainBackground } from './components/main-background';
import { useEffect, useRef, useState } from 'react';
import { Conversation } from './api/models/Conversation';
import { getUser } from './api/thunks/users';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { TryIt } from './components/try-it-component';
import { createConversation, getAllConversations, getAllSupportedTickers, getConversation, watchConversation } from './api/thunks/conversations';
import { setNotification } from './store/notifications-reducer';
import { PricingComponent } from './components/pricing-component';
import { ComparisonComponent } from './components/comparison-component';
import SpotlightSearchOverlay from './components/spotlight-search-component';

export function App() {
  const dispatch = useAppDispatch();
  const credentials = useAppSelector((state) => state.user.credentials);

  const [convo, setConvo] = useState<Conversation | undefined>();
  const [allConvos, setAllConvos] = useState<Conversation[]>([]);
  const [supportedTickers, setSupportedTickers] = useState<string[]>([]);
  const websocket = useRef<WebSocket>()
  let wsPongTimeout: NodeJS.Timeout;

  const handleSearchValueClicked = async (value: string) => {
    if (!credentials) return;
    const res = await createConversation({ ticker: value }, credentials.accessToken, dispatch);
    if (res?.id) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('conversationId', res.id);
      window.location.href = '/dashboard' + '?' + queryParams.toString();
    }
  }

  const router = createBrowserRouter([
    {
      path: "/health",
      element: <div>Healthy</div>,
    },
    {
      path: "/",
      element: <SplashPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/company",
      element: <CompanyComponent />
    },
    {
      path: "/features",
      element: <FeaturesComponent />
    },
    {
      path: "/changelog",
      element: <ChangelogComponent />
    },
    {
      path: '/terms-of-service',
      element: <TermsOfServiceComponent></TermsOfServiceComponent>,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicyComponent></PrivacyPolicyComponent>,
    },
    {
      path: "/profile",
      element:
        <MainBackground convos={allConvos} setConvos={setAllConvos} currConvo={convo} supportedTickers={supportedTickers} showMenu={true}>
          <ProfileComponent />
        </MainBackground>,
    },
    {
      path: '/signup',
      element: <SignUpComponent></SignUpComponent>,
      errorElement: <ErrorPage />,
    },
    {
      path: '/password-reset',
      element: <ForgotPasswordComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login",
      element: <LoginComponent></LoginComponent>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/dashboard",
      element:
        <>
          <SpotlightSearchOverlay placeholder='Search companies by ticker...' searchValues={['MSFT', 'ADBE', 'AAPL', 'AAB', 'AMCR', 'MMM']} handleSearchValueClicked={handleSearchValueClicked} show={allConvos.length === 0} />
          <MainBackground convos={allConvos} setConvos={setAllConvos} currConvo={convo} supportedTickers={supportedTickers} showMenu={true}>
            <DashboardComponent convo={convo} setConvo={setConvo} />
          </MainBackground>
        </>,
      errorElement: <ErrorPage />,
    },
    {
      path: '/try-it',
      element: <TryIt />
    },
    {
      path: '/pricing',
      element: <PricingComponent />
    },
    {
      path: '/pq-v-chatgpt',
      element:
        <MainBackground convos={allConvos} setConvos={setAllConvos} currConvo={convo} supportedTickers={supportedTickers} showMenu={false}>
          <ComparisonComponent />
        </MainBackground>,
    }
  ]);

  const setupWebsocket = () => {
    if (websocket.current === undefined || !websocket.current?.OPEN) {
      websocket.current = new WebSocket(process.env.REACT_APP_API_URL + "/v1/events");
      websocket.current.onopen = () => {
        console.log("websocket connected, sending auth message");
        if (!websocket.current || !credentials) {
          console.log("no websocket or no credentials ", websocket.current, credentials);
          return;
        }
        websocket.current.send(JSON.stringify({ type: "authorization", data: credentials.accessToken, }));

        wsPongTimeout = setInterval(() => {
          console.log('ponging')
          websocket.current?.send(JSON.stringify({ type: "pong" }));
        }, 500);
      };


      websocket.current.onmessage = async (event) => {
        const msg = JSON.parse(event.data);

        if (msg.type === 'ping') {
          return;
        }

        if (!credentials) {
          return;
        }

        console.log("websocket message", msg);
        switch (msg.type) {
          case "run_completed":
            console.log('received conversation updated', msg);
            const updatedConvo = await getConversation(msg.data.conversation_id, credentials.accessToken);
            setConvo(updatedConvo);
            break;
          case "free_credit_used":
            console.log('received free credit used');
            dispatch(getUser());
            break;
        }
      };

      websocket.current.onclose = () => {
        console.log("websocket closed, reconnecting in 5 seconds");
        clearTimeout(wsPongTimeout);
        setTimeout(() => {
          setupWebsocket();
        }, 5000);
      }
    }
  }

  useEffect(() => {
    if (!credentials) return;

    setupWebsocket();
    dispatch(getUser());
    const query = new URLSearchParams(window.location.search);
    const conversationId = query.get('conversationId');

    getAllConversations(credentials.accessToken).then((res) => {
      setAllConvos(res);
      if (!conversationId && res.length > 0) {
        query.set('conversationId', res[0].id);
        window.location.href = window.location.pathname + '?' + query.toString();
      }
    }).catch((err) => {
      dispatch(setNotification({
        message: 'Failed to get conversations',
        type: 'error'
      }));
    });

    if (conversationId && window.location.href.includes('dashboard')) {
      getConversation(conversationId, credentials.accessToken).then((res) => {
        setConvo(res);
        watchConversation(res.ticker, credentials.accessToken).catch((err) => {
          dispatch(setNotification({
            message: 'Failed to watch. Message updates may not appear',
            type: 'error'
          }));
        });
      }).catch((err) => {
        dispatch(setNotification({
          message: 'Failed to get conversation',
          type: 'error'
        }));
      });
    }

    getAllSupportedTickers(credentials.accessToken).then((res) => {
      setSupportedTickers(res);
    }).catch((err) => {
      dispatch(setNotification({
        message: 'Failed to get supported tickers',
        type: 'error'
      }));
    });

  }, [credentials])

  return (
    <RouterProvider router={router} />
  );
}

