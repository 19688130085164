// @ts-nocheck
import { useEffect, useState } from 'react';
import { Message } from '../api/models/Message';
import { MessageContent } from '../api/models/MessageContent';
import { Conversation } from '../api/models/Conversation';

// Simple React component
export const LlmConvoComponent = ({ convo, sendMessage, message, setMessage, getCitationTextDetails }:
    {
        convo: Conversation | undefined
        sendMessage: (message: string) => Promise<boolean>;
        message: string;
        setMessage: (message: string) => void;
        getCitationTextDetails: (conversationId: string, messageId: string, fileId: string) => Promise<string>;
    }
) => {
    const sendMessageInput = document.getElementById('send-message-input');
    const messageDiv = document.getElementById('message-div');
    const [tooltip, setTooltip] = useState({ visible: false, title: '', position: { x: 0, y: 0 } });
    const [isShowingFullCitation, setIsShowingFullCitation] = useState(false);
    const [messageInFlight, setMessageInFlight] = useState(false);
    const [expandedDetails, setExpandedDetails] = useState<{ [key: string]: boolean }>({});

    const showTooltip = (title: string, event: React.MouseEvent) => {
        const tooltipHeight = 100; // Height of the tooltip in px
        const yOffset = 10; // Offset from the clicked citation

        setTooltip({
            visible: true,
            title: title,
            position: { x: event.clientX, y: event.clientY - tooltipHeight - yOffset } // Adjust tooltip position to appear above
        });
    };

    const hideTooltip = () => {
        setTooltip({ visible: false, title: '', position: { x: 0, y: 0 } });
    };

    const handleMessageInputChange = (e: any) => {
        setMessage(e.target.value);
    };

    const handleEnterPressed = (e: any) => {
        if (e.key === 'Enter') {
            sendMessageLocal();
        }
    };

    useEffect(() => {
        if (sendMessageInput) {
            sendMessageInput.addEventListener('keydown', handleEnterPressed, {
                passive: true
            });
        }

        return () => {
            if (sendMessageInput) {
                sendMessageInput.removeEventListener('keydown', handleEnterPressed);
            }
        };
    }, [sendMessageInput, message, convo]);

    useEffect(() => {
        if (messageDiv) {
            messageDiv.scrollTop = messageDiv.scrollHeight;
        }
    }, [messageDiv, convo]);

    function addCitationLinksToMessage(content: MessageContent, rootMessage?: Message) {
        if (!rootMessage || !content.fileCitations || content.fileCitations.length === 0) {
            return <div key="citation-1st-clause" dangerouslySetInnerHTML={
                { __html: content.text }
            }></div>;
        }

        // Replace  with <a href=""> </a>
        const citationRegex = /【(\d+:\d+)†(.+?)】/g;
        const matches = Array.from(content.text.matchAll(citationRegex) || []); // Convert matches to an array
        let newcontentParts = [];
        let lastIndex = 0;

        for (const match of matches) {
            const citationText = match[0];
            const citation = content.fileCitations.find((c) => c.text === citationText);
            if (!citation) {
                continue;
            }

            const text = citation.text || '';
            const startIndex = content.text.indexOf(text, lastIndex);
            const endIndex = startIndex + text.length;
            const fileName = citation.fileName.split(".")[0];

            // Add the text before the citation
            newcontentParts.push(content.text.slice(lastIndex, startIndex));

            // Add the citation link
            newcontentParts.push(
                <a
                    id='citation-link'
                    onClick={async (e) => {
                        const fullCitation = await getCitationTextDetails(convo?.id || '', rootMessage.id || '', citation.fileId || '');
                        setIsShowingFullCitation(true);
                        showTooltip(`${fileName} ${fullCitation}` || 'Citation text came back empty. This could be due to a hallucination', e);

                        const tooltip = document.querySelector("#citation-link");
                        if (tooltip) {
                            // set a timeout to ensure that the mouseOver event isn't triggered repeadetly just because of small mouse movements, resulting in the filename being rendeder instead of the long one citation
                            setTimeout(() => {
                                setIsShowingFullCitation(false);
                            }, 5000);
                        }
                    }}
                    className="text-blue-500 hover:underline cursor-pointer"
                >
                    {text}
                </a>
            );

            // Update lastIndex to the end of the current citation
            lastIndex = endIndex;
        }

        // Add the remaining text after the last citation
        newcontentParts.push(content.text.slice(lastIndex));

        return <div>{newcontentParts}</div>;
    }

    const sendMessageLocal = async () => {
        setMessageInFlight(true);
        const res = await sendMessage(message);
        if (res) {
            setMessage('');
        }
        setMessageInFlight(false);
    }

    const handleToggleDetails = (id: string) => {
        setExpandedDetails(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const formatFunctionName = (str) => {
        // Replace underscores with spaces, then add space before capital letters and convert to lowercase
        return str
            .replace(/_/g, ' ')                    // Replace underscores with spaces
            .replace(/([a-z])([A-Z])/g, '$1 $2')    // Add space before capital letters
            .toLowerCase();                        // Convert the entire string to lowercase
    };    

    const renderRunDetails = (msg : Message) => {
        const allFunctionCalls = msg.runDetails
            .flatMap(detail => detail.type === "tool_calls" 
                ? detail.tool_calls.filter(call => call.type === "function") 
                : []
            );
    
        const funcId = `function-calls-group-${msg.id}`; // ID for the entire function calls group
        return (
            <div className="mt-4">
                {/* Subtle hint for Function Calls */}
                {allFunctionCalls.length > 0 && (
                    <div className="mb-4 p-2 border border-gray-200 rounded bg-gray-50">
                        <div
                            className="tool-title text-sm text-gray-500 cursor-pointer"
                            onClick={() => handleToggleDetails(funcId)}
                        >
                            {expandedDetails[funcId] ? "▲ Hide Tool Use" : "► Show Tool Use"}
                        </div>
                        {expandedDetails[funcId] && (
                            <div className="ml-4 mt-2 text-gray-700">
                                {allFunctionCalls.map((call, idx) => {
                                    const callId = call.id
                                    return (
                                        <div key={callId} className="mb-2">
                                            <div
                                                className="tool-title text-blue-400 cursor-pointer"
                                                onClick={() => handleToggleDetails(callId)}
                                            >
                                                {formatFunctionName(call.function.name)}
                                            </div>
                                            {expandedDetails[callId] && (
                                                <div className="ml-4 mt-2 p-2 border-l-4 border-blue-300 bg-blue-50 rounded">
                                                    <p style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                                                        <strong>Arguments:</strong><br />
                                                        <code style={{fontSize: '12px'}}>{JSON.stringify(JSON.parse(call.function.arguments), null, 2)}</code>
                                                    </p>
                                                    <p style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                                                        <strong>Output:</strong> {call.function.output}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
    
                {/* Subtle hint for File Search Results */}
                {msg.runDetails.map((detail, index) => {
                    if (detail.type === "tool_calls") {
                        const fileSearchCalls = detail.tool_calls.filter(call => call.type === "file_search");
                        if (fileSearchCalls.length === 0) {
                            return null;
                        }
                        const fileSearchId = `file-search-group-${msg.id}`;
                        
                        return (
                            <div key={`tool-call-${index}`} className="mb-4 p-2 border border-gray-200 rounded bg-gray-50">
                                {fileSearchCalls.length > 0 && (
                                    <div>
                                        <div
                                            className="tool-title text-sm text-gray-500 cursor-pointer"
                                            onClick={() => handleToggleDetails(fileSearchId)}
                                        >
                                            {expandedDetails[fileSearchId] ? "▲ Hide File Search Results" : "► Show File Search Results"}
                                        </div>
                                        {expandedDetails[fileSearchId] && (
                                            <div className="ml-4 mt-2 text-gray-700">
                                                {fileSearchCalls.map((call, idx) =>
                                                    call.file_search.results.map((file, fileIdx) => {
                                                        const fileId = `${fileSearchId}-file-${fileIdx}`;
                                                        return (
                                                            <div key={fileId}>
                                                                <span
                                                                    className="text-blue-400 cursor-pointer"
                                                                    onClick={() => handleToggleDetails(fileId)}
                                                                >
                                                                    {file.file_name}
                                                                </span>
                                                                {expandedDetails[fileId] && (
                                                                    <div className="ml-4 mt-1 p-2 border-l-4 border-blue-300 bg-blue-50">
                                                                        {file.content.map((contentItem, contentIdx) => (
                                                                            <p key={contentIdx} style={{ overflowWrap: 'break-word' }}>
                                                                                {contentItem.text}
                                                                            </p>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    })
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };
    

    return (
        <>
            <div className="bg-sky-500/[.06] rounded-lg p-4 flex-1 mr-4 relative">
                <div className="flex mb-4">
                    <img src={`/company_icons/${convo?.ticker}.png`} alt={`${convo?.ticker}-logo`} className="w-8 h-8" />
                    <h1 className="text-2xl font-bold ml-4">{convo?.ticker}</h1>
                </div>

                <div id='message-div' className="flex flex-col items-start space-y-2 mt-3 scroll-smooth overflow-auto" style={{ height: '65vh' }}>
                    {convo && convo.messages && convo.messages.map((msg: any) => {
                        if (msg.role === Message.RoleEnum.User) {
                            return (
                                <div key={msg.id} className="bg-blue-500 rounded-lg py-2 px-4 max-w-xs text-white self-end">
                                    {msg.content.map((c: any, i: any) => (
                                        <p key={i}>{c.text}</p>
                                    ))}
                                </div>
                            );
                        }
                        return (
                            <div key={msg.id} className="bg-gray-200 rounded-lg py-2 px-4 max-w-xs">
                                {msg.content.map((c: any, i: any) => (
                                    <div key={i} dangerouslySetInnerHTML={{ __html: c.text }}></div>
                                ))}
                                {msg.runDetails && renderRunDetails(msg)}
                            </div>
                        );
                    })}
                </div>
                <div className="relative mt-2">
                    <input
                        id='send-message-input'
                        type='text'
                        className='w-full border-2 rounded-lg p-2'
                        placeholder='Type a message...'
                        onChange={handleMessageInputChange}
                        value={message}
                    />
                    <button disabled={messageInFlight} onClick={sendMessageLocal} className="absolute right-2 top-2 text-blue-500 hover:underline">Send</button>
                    {/* text with warning icon saying that llms hallucinate and to always verify claims. Clicking a citation will reload the page with that file present. */}
                    <div className="flex items-center mt-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill="orange" />
                        </svg>
                        <p className="text-sm text-gray-500" style={{
                            maxHeight: '4vh',
                            overflow: 'auto'
                        }}>AI makes mistakes. Check the facts.</p>
                    </div>
                </div>
            </div>
            {tooltip.visible && (
                <div
                    className="absolute bg-white border border-gray-300 rounded-lg p-2 shadow-lg overflow-y-auto"
                    style={{
                        top: tooltip.position.y,
                        left: tooltip.position.x,
                        maxHeight: '200px', // Adjust the height as needed
                        width: '200px', // Adjust the width as needed
                        zIndex: 1000
                    }}
                    onMouseLeave={hideTooltip}
                >
                    <div className="text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: tooltip.title }}></div>
                </div>
            )}
        </>
    );
};
