export const navigationRoutes = [
    { name: 'Home', href: '/' },
    { name: 'Try It', href: '/try-it' },
    { name: 'PocketQuant v. ChatGPT', href: '/pq-v-chatgpt'},
    { name: 'Pricing', href: '/pricing' },
    { name: 'Changelog', href: '/changelog' },
]

export const funnelRoutes = [
    { name: 'Home', href: '/' },
    { name: 'Try It', href: '/try-it' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'PocketQuant v. ChatGPT', href: '/pq-v-chatgpt'},
    { name: 'SignUp', href: '/signup' },
]