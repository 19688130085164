import axios from "axios";
import { CreateMessageInput } from "../models/CreateMessageInput";
import { Conversation } from "../models/Conversation";
import { CreateConversationInput } from "../models/CreateConversationInput";
import { PostOkResponse } from "../models/PostOkResponse";
import { setNotification } from "../../store/notifications-reducer";

// Async function to create a message
export const createMessage = async (
    conversationId: string,
    body: CreateMessageInput,
    accessToken: string,
    dispatch: (action: any) => void
): Promise<string | undefined> => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + `/v1/conversations/${conversationId}/messages`,
            body, 
            {
                headers: { 'Authorization': accessToken  }
            }
        );

        if (response.status !== 201) {
            dispatch(setNotification({
                message: 'Error sending message',
                type: 'error'
            }));
            return undefined;
        }

        return response.data.id;
    } catch (error) {
        const response = (error as any).response;

        if (!response) {
            dispatch(setNotification({
                message: 'Error sending message',
                type: 'error'
            }));
            return;
        }

        if (response.status === 403 && response.data.code === 1) {
            let message = 'Message limit reached. Please upgrade your plan or enable overages.';
            if (response.data.plan === 'free') {
                message = 'Free credits exhausted. Please upgrade to a premium plan.';
            }
            dispatch(setNotification({
                message,
                type: 'error'
            }));
        }

        return undefined;
    }
};

// Async function to get all conversations
export const getAllConversations = async (
    accessToken: string 
): Promise<Conversation[]> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/v1/conversations', {
        headers: { 'Authorization': accessToken  }
    });

    if (response.status !== 200) {
        throw new Error('Failed to get conversations');
    }

    return response.data as Conversation[];
};

// Async function to delete a conversation
export const deleteConversation = async (
    conversationId: string,
    accessToken: string ,
    dispatch: (action: any) => void
): Promise<string> => {
    const response = await axios.delete(process.env.REACT_APP_API_URL + `/v1/conversations/${conversationId}`, {
        headers: { 'Authorization': accessToken  }
    });

    if (response.status !== 204) {
        throw new Error('Failed to delete conversation');
    }

    dispatch(setNotification({
        message: 'Conversation deleted',
        type: 'success'
    }));

    return conversationId;
};

// Async function to create a new conversation
export const createConversation = async (
    input: CreateConversationInput,
    accessToken: string ,
    dispatch: (action: any) => void
): Promise<PostOkResponse> => {
    const response = await axios.post(process.env.REACT_APP_API_URL + `/v1/conversations`, input, {
        headers: { 'Authorization': accessToken  }
    });

    if (response.status !== 201) {
        dispatch(setNotification({
            message: 'Error creating conversation',
            type: 'error'
        }));
        throw new Error('Failed to create conversation');
    }

    dispatch(setNotification({
        message: 'Conversation created',
        type: 'success'
    }));

    return response.data as PostOkResponse;
};

// Async function to watch a conversation
export const watchConversation = async (
    ticker: string,
    accessToken: string 
): Promise<void> => {
    const response = await axios.post(process.env.REACT_APP_API_URL + `/v1/events/conversations/${ticker}/watch`, {}, {
        headers: { 'Authorization': accessToken  }
    });

    if (response.status !== 200) {
        throw new Error('Failed to watch conversation');
    }
};

// Async function to get all supported tickers
export const getAllSupportedTickers = async (
    accessToken: string 
): Promise<string[]> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/v1/tickers', {
        headers: { 'Authorization': accessToken  }
    });

    if (response.status !== 200) {
        throw new Error('Failed to get supported tickers');
    }

    return response.data as string[];
};

// Async function to get a specific conversation
export const getConversation = async (
    conversationId: string,
     accessToken: string
): Promise<Conversation> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/conversations/${conversationId}`, {
        headers: { 'Authorization': accessToken  }
    });

    if (response.status !== 200) {
        throw new Error('Failed to get conversation');
    }

    return response.data as Conversation;
};
