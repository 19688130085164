import { Dialog, DialogPanel } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import { useAppSelector } from "../store/hooks"
import { useNavigate } from "react-router-dom"
import { ConversationListComponent } from "./conversation-list-component"
import NotificationPopup from "./notification-popup-component"
import { Conversation } from "../api/models/Conversation"
export const MainBackground = ({ children, convos, currConvo, supportedTickers, setConvos, showMenu }:
    {
        children: any;
        convos: Conversation[];
        currConvo: Conversation | undefined;
        supportedTickers: string[];
        setConvos: (val: Conversation[]) => void;
        showMenu: boolean;
    }) => {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.user.user);
    const navigation = [
        { name: 'Profile', href: '/profile' },
        { name: 'Dashboard', href: '/dashboard' },
    ];

    const currentPath = window.location.pathname;
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const selectedMobileViews = queryParams.get('view')?.split(',') || getDefaultSelectedViews();

    // Step 1: Define State for Selected Views
    const [selectedViews, setSelectedViews] = useState<string[]>(selectedMobileViews || []);

    // Step 2: Define Available Views
    const availableViews = [
        { label: 'Earnings', value: 'earnings' },
        { label: 'LLM Convo', value: 'conversation' },
        { label: 'Transcript', value: 'transcript' },
    ];

    // Step 3: Handle Change Event
    const handleViewChange = (viewValue: string) => {
        setSelectedViews(prev => {
            if (prev.includes(viewValue)) {
                return prev.filter(v => v !== viewValue); // Uncheck
            } else {
                return [...prev, viewValue]; // Check
            }
        });
    };

    // Step 5: Render Checkboxes
    const renderViewCheckboxes = () => {
        return availableViews.map(view => (
            <div key={view.value} className="flex items-center mb-4">
                <input
                    type="checkbox"
                    id={view.value}
                    name={view.value}
                    checked={selectedViews.includes(view.value)}
                    onChange={() => handleViewChange(view.value)}
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label htmlFor={view.value} className="ml-2 text-sm font-medium text-gray-900">
                    {view.label}
                </label>
            </div>
        ));
    };

    function getDefaultSelectedViews() {
        if (window.innerWidth <= 768) {
            return ['conversation']
        }

        return ['earnings', 'conversation', 'transcript'];
    }

    // Step 6: Effect to Update Navigation
    useEffect(() => {
        if (currentPath !== '/dashboard') {
            return;
        }
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('views'); // Clear existing views
        if (selectedViews.length > 0) {
            queryParams.set('views', selectedViews.join(',')); // Add selected views
        }
        navigate('/dashboard' + '?' + queryParams.toString(), { replace: true });
    }, [selectedViews, navigate]);

    return (
        <div className="bg-white overflow-scroll overflow-hidden md:overflow-scroll" style={{ maxHeight: '100vh' }}>
            <NotificationPopup />
            <header className=" inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    {/* can put logo here */}
                    {/* <div className="flex lg:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">PocketQuant</span>
                            <img
                                className="h-8 w-auto"
                                src="pq-icon-512.png"
                                alt=""
                            />
                        </a>
                    </div> */}
                    <div className="flex rtl z-40">
                        {showMenu && (
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        )}
                    </div>
                    {
                        user?.plan === 'free' && currentPath === '/dashboard' && (
                            <div className="flex items-center justify-end space-x-4">
                                {/* free credits remaining in green or red text */}
                                <p className="text-sm font-bold" style={{ color: user.messagesRemaining === 0 ? 'red' : 'green' }}>
                                    {user.messagesRemaining} free prompts
                                </p>
                                <button className="z-40 flex px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 hidden md:block" onClick={() => {
                                    navigate('/profile');
                                }}>
                                    Upgrade to Premium
                                </button>
                                <button className="z-40 flex px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 md:hidden" onClick={() => {
                                    navigate('/profile');
                                }}>
                                    Upgrade
                                </button>
                            </div>
                        )
                    }
                </nav>
                <Dialog className="" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-40" />
                    <DialogPanel className="fixed inset-y-0 left-0 z-40 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">PocketQuant</span>
                                <img
                                    className="h-8 w-auto"
                                    src="pq-icon-512.png"
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>

                                {currentPath === '/dashboard' && (
                                    <>
                                        <div className="space-y-2">
                                            <ConversationListComponent convos={convos} currConvo={currConvo} supportedTickers={supportedTickers} setConvos={setConvos} />
                                        </div>

                                        <div className="view-picker">
                                            <div className="font-semibold text-lg mb-2">Views</div>
                                            {renderViewCheckboxes()}
                                        </div>
                                    </>
                                )}

                                <div className="py-6">
                                    <a
                                        href="/login"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        onClick={() => {
                                            sessionStorage.clear();
                                            // websocket.current?.close();
                                            navigate("/");
                                        }}
                                    >
                                        Log out
                                    </a>
                                </div>
                                {user === undefined && (
                                    <div className="py-6">
                                        <a
                                            href="/signup"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            Signup
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>

            <div className="relative isolate">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff7f50] to-[#ffa500] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                {children}
            </div>
        </div>
    )
}
