
export const PrivacyPolicyComponent = () => {
    document.title = "Privacy Policy | PocketQuant";
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-10 w-auto"
                    src="pq-icon-512.png"
                    alt="PocketQuant Logo"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Privacy Policy
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <p>
                        Last Updated: July 30, 2024
                    </p>

                    <p>
                        Pocket Quant ("we", "us", or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services, including our website, mobile application, and any other services we provide (collectively, the "Services"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Services.
                    </p>

                    <h3>
                        1. Information We Collect
                    </h3>

                    <p>
                        We may collect information about you in a variety of ways. The information we may collect on the Services includes:
                    </p>

                    Personal Data

                    Personally identifiable information, such as your name, shipping address, email address, and demographic information (such as that which can be obtained from 3rd party analytics providers such as Google Analytics), that you voluntarily give to us when you register with the Services or when you choose to participate in various activities related to the Services.

                    Derivative Data

                    Information our servers automatically collect when you access the Services, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Services.

                    Financial Data

                    Financial information, such as data related to your payment method (e.g., valid credit card last 3 or 4 digits, card brand, expiration date) that we may collect when you subscribe to, or cancel your subscription to our services from the Services. We store only very limited, if any, financial information. Otherwise, all financial information is stored by our payment processor (Stripe), and you are encouraged to review their privacy policy and contact them directly for responses to your questions.

                    <h3>
                        2. Use of Your Information
                    </h3>

                    <p>
                        Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Services to:

                        Create and manage your account.
                        Process your transactions and send you related information, including purchase confirmations and invoices.
                        Send you technical notices, updates, security alerts, and support and administrative messages.
                        Manage your subscriptions and send you related emails.
                        Request feedback and contact you about your use of the Services.
                        Resolve disputes and troubleshoot problems.
                        Respond to product and customer service requests.
                        Send you a newsletter or other marketing materials.
                        Improve the Services and develop new products, services, features, and functionality.
                    </p>

                    <h3>3. Disclosure of Your Information</h3>
                    <p>
                        We may share information we have collected about you in certain situations. Your information may be disclosed as follows:

                        By Law or to Protect Rights

                        If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.

                        Service Providers

                        We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.

                        Business Transfers

                        We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

                        We may disclose your personal information for any other purpose with your prior written consent.
                    </p>

                    <h3>4. Use of Information With Regards to Large Language Models (LLMs)</h3>
                    <p>
                        Your prompts and answers from the LLMs are not used to train the LLMs that we provide access to. Your prompts and the answers provided to you are stored with the model provider and not with us.
                        We encourage you to not share any personal, confidential, or sensitive information with the LLMs.
                    </p>

                    <h3>5. Security of Your Information</h3>
                    <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any data associated with our services are encrypted at rest and in transit using industry standard technologies.</p>

                    <h3>6. Policy for Children</h3>
                    <p>We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible.</p>

                    <h3>7. Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time, without prior notice to you, in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. You are advised to review this Privacy periodically.</p>

                    <h3>8. Contact Us</h3>
                    <p>For any concerns regarding your privacy or our privacy policies reach out to our founder Tristan Janicki at tristan@pocket-quant.com</p>
                </div>
            </div>
        </div>
    )
}