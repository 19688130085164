
export const TermsOfServiceComponent = () => {
    document.title = "Terms of Service | PocketQuant";
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-10 w-auto"
                    src="pq-icon-512.png"
                    alt="PocketQuant Logo"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Terms of Service
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <p>
                        Last Updated: August 8, 2024
                    </p>
                    <p>
                        PocketQuant is provided as is with no guarantees or warranties. Use at your own risk.
                    </p>
                    <p>
                        PocketQuant's intelligent features are powered by Artificial Intelligence which is an experimental technology and should be treated as such. The models are probabilistic in nature and will be wrong and/or hallucinate at times.
                    </p>
                    <p>
                        Always independently verify the results of any output before making any decisions.
                    </p>
                    <p>
                        The purpose of PocketQuant is to provide an educational and exploratory tool for financial datasets. It is not a platform for soliciting advice. Our LLMs have been instructed to not give financial advice but instead help solely with data analysis, exploration, and research. Using PocketQuant to obtain advice on financial decisions is done at your own risk and in violation of our policies. Always verify facts for yourself.   
                    </p>
                    <p>
                        PocketQuant reserves the right to change or discontinue the service or its price at any time, without prior notification.
                    </p>
                    <p>
                        Do not share private, sensitive, or confidential information with the Large Language Models that power PocketQuant's intelligent features.
                    </p>
                    <p>
                        Free trial (LLM token consumption) for new users are provided at the discretion of PocketQuant and may be changed or discontinued at any time. Each individual (person, company, or entity) is limited to one free trial unless given prior written permission by PocketQuant. 
                    </p>
                    <p>
                        Individuals found to be engaging in activities that are deemed to have the intent of circumventing or abusing the 1 free trial per indivdual policy (such as creating multiple accounts) will be banned and legal action may be purused.
                    </p>
                    <p>
                        User accounts may be terminated for any reason and without prior notice at the discretion of PocketQuant.
                    </p>
                    <p>
                        PocketQuant is in no way affiliated or a representitive of any of the companies or entities whose data is used/displayed or otherwise incorporated into the service.
                    </p>
                </div>
            </div>
        </div>
    );
}