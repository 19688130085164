import axios from "axios";
import { EarningsReport } from "../models/models";
import { EarningsDate } from "../models/EarningsDate";
import { setNotification } from "../../store/notifications-reducer";

// Async function to get the earnings report
export const getEarningsReport = async (
    ticker : string, 
    fiscalDateEnding : string,
    accessToken: string,
    dispatch: (action: any) => void
): Promise<EarningsReport> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/stocks/${ticker}/earnings/${fiscalDateEnding}`, {
        headers: { 'Authorization': accessToken }
    });

    if (response.status !== 200) {
        if (response.status === 403 && response?.data?.code === 1) {
            dispatch(setNotification({
                message: 'You do not have access to this earnings report. Please upgrade to premium to access all earnings.',
                type: 'error'
            }));
        }
        throw new Error('Failed to get earnings report');
    }

    return response.data as EarningsReport;
};

// Async function to get the earnings dates
export const getEarningsDates = async (
    { ticker, entityType } : { ticker: string, entityType: 'transcript' | 'earnings' },
    accessToken: string
): Promise<{ entityType: 'transcript' | 'earnings', dates: EarningsDate[] }> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/stocks/${ticker}/earnings_dates?entityType=${entityType}`, {
        headers: { 'Authorization': accessToken }
    });

    if (response.status !== 200) {
        throw new Error('Failed to get earnings dates');
    }

    return {
        entityType: entityType,
        dates: response.data as EarningsDate[]
    };
};
