/**
 * PQ BE
 * A be service for  pq
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

/**
 * A citation within the message that points to a specific quote from a specific File associated with the assistant or the message. Generated when the assistant uses the \"retrieval\" tool to search files.
 */
export interface FileCitation {
    entityType?: FileCitation.TypeEnum;

    /**
     * Text in the message that needs to be replaced
     */
    text?: string;

    fiscalDateEnding?: string;

    startIndex: number;
    
    endIndex: number;

    fileId: string;

    fileName: string;
}
export namespace FileCitation {
    export enum TypeEnum {
        Earninngs = <any> 'earninngs',
        Transcript = <any> 'transcript',
    }
}
