import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import { EarningsReportComponent } from "./earnings-report-component"
import { LlmConvoComponent } from "./llm-convo-component"
import { TranscriptComponent } from "./transcript-component"
import { useLocation } from "react-router-dom"
import { createMessage } from "../api/thunks/conversations"
import { Conversation } from "../api/models/Conversation"
import { Message } from "../api/models/Message"
import { setNotification } from "../store/notifications-reducer"
import { Transcript } from "../api/models/Transcript"
import { EarningsReport } from "../api/models/EarningsReport"
import axios from "axios"
import { getEarningsTranscript } from "../api/thunks/transcripts"
import { getEarningsDates, getEarningsReport } from "../api/thunks/financials"
import { EarningsDate } from "../api/models/EarningsDate"

export const DashboardComponent = ({
    convo,
    setConvo
}: {
    convo: Conversation | undefined;
    setConvo: (convo: Conversation) => void;
}) => {
    document.title = "Dashboard | PocketQuant";
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((state) => state.user.credentials);

    const [transcript, setTranscript] = useState<Transcript | undefined>();
    const [earningsReport, setEarningsReport] = useState<EarningsReport | undefined>();
    const [message, setMessage] = useState('');
    const [earningsFiscalDateEnding, setEarningsFiscalDateEnding] = useState('');
    const [transcriptFiscalDateEnding, setTranscriptFiscalDateEnding] = useState('');

    const [transcriptEarningsDates, setTranscriptEarningsDates] = useState<EarningsDate[]>([]);
    const [earningsReportsDates, setEarningsReportsDates] = useState<EarningsDate[]>([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let views = ['all'];
    if (queryParams.get('views') !== null) {
        views = queryParams.get('views')!.split(',');
    }

    const sendMessage = async (): Promise<boolean> => {
        if (!credentials) {
            dispatch(setNotification({
                message: 'Please login to send a message',
                type: 'error'
            }));
            return false;
        }
        if (convo === undefined) {
            dispatch(setNotification({
                message: 'Please select a ticker to start a conversation',
                type: 'error'
            }));
            return false;
        }
        if (convo && message.length > 0 && earningsFiscalDateEnding && transcriptFiscalDateEnding) {
            const msgId = await createMessage(convo.id, {
                content: message,
                frontendContext: {
                    earningsFiscalDateEnding: earningsFiscalDateEnding,
                    transcriptFiscalDateEnding: transcriptFiscalDateEnding
                },
            }, credentials.accessToken, dispatch);

            if (!msgId) {
                return false;
            }

            const convoDeepCopy = JSON.parse(JSON.stringify(convo)) as Conversation;
            convoDeepCopy.messages?.push({
                id: msgId,
                role: Message.RoleEnum.User,
                content: [{ text: message }]
            });
            setConvo(convoDeepCopy);
        }

        return true;
    };

    async function getCitationTextDetails(conversationId: string, messageId: string, fileId: string) {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/conversations/${conversationId}/messages/${messageId}/citation?fileId=${fileId}`, {
                headers: {
                    'Authorization': credentials?.accessToken
                }
            });

            if (response.status !== 200) {
                dispatch(setNotification({
                    message: 'Failed to get citation details. This indicates it may be a hallucination or the file structure has changed.',
                    type: 'error'
                }));
            }

            console.log(response.data)
            if (response.data === undefined) {
                return 'Could not find citation details. This may indicate a halucination'
            }

            return response.data;
        } catch (e: any) {
            if (e?.response?.status === 404) {
                dispatch(setNotification({
                    message: 'Failed to get citation details. This indicates it may be a hallucination or the file structure has changed.',
                    type: 'error'
                }));
            }
        }
    }

    useEffect(() => {
        if (!credentials) {
            return;
        }

        const query = new URLSearchParams(location.search);
        const queryEarningsFiscalDateEnding = query.get('earningsFiscalDateEnding');
        const queryTranscriptFiscalDateEnding = query.get('transcriptFiscalDateEnding');
        let setTranscriptFiscalDateEndingFromQuery = false;
        let setEarningsFiscalDateEndingFromQuery = false;
        if (queryEarningsFiscalDateEnding !== null) {
            setEarningsFiscalDateEnding(queryEarningsFiscalDateEnding);
            setEarningsFiscalDateEndingFromQuery = true;
        }
        if (queryTranscriptFiscalDateEnding !== null) {
            setTranscriptFiscalDateEnding(transcriptFiscalDateEnding);
            setTranscriptFiscalDateEndingFromQuery = true;
        }

        if (!convo) return;

        getEarningsDates({ ticker: convo.ticker, entityType: 'transcript' }, credentials.accessToken).then((data) => {
            const mostRecentWithAccess = data.dates.find((date) => date.userHasAccess);
            if (!mostRecentWithAccess) {
                return
            }
            setTranscriptEarningsDates(data.dates);
            if (!setTranscriptFiscalDateEndingFromQuery) {
                setTranscriptFiscalDateEnding(mostRecentWithAccess!.date);
            }
        }).catch((e) => {
            dispatch(setNotification({
                message: 'Failed to get transcript earnings dates',
                type: 'error'
            }));
        });

        getEarningsDates({ ticker: convo.ticker, entityType: 'earnings' }, credentials.accessToken).then((data) => {
            const mostRecentWithAccess = data.dates.find((date) => date.userHasAccess);
            if (!mostRecentWithAccess) {
                return
            }
            setEarningsReportsDates(data.dates);
            if (!setEarningsFiscalDateEndingFromQuery) {
                setEarningsFiscalDateEnding(mostRecentWithAccess!.date);
            }
        }).catch((e) => {
            console.log(e);
            dispatch(setNotification({
                message: 'Failed to get earnings dates',
                type: 'error'
            }));
        });
    }, [credentials, location.search, convo]);

    useEffect(() => {
        if (!credentials) {
            return;
        }

        if (convo && transcriptFiscalDateEnding) {
            getEarningsTranscript(convo.ticker, transcriptFiscalDateEnding, credentials.accessToken, dispatch).then((data) => {
                setTranscript(data);
            }).catch((e) => {
                dispatch(setNotification({
                    message: 'Failed to get transcript',
                    type: 'error'
                }));
            });
        }
    }, [transcriptFiscalDateEnding])

    useEffect(() => {
        if (!credentials) {
            return;
        }

        if (convo && earningsFiscalDateEnding) {
            getEarningsReport(convo.ticker, earningsFiscalDateEnding, credentials.accessToken, dispatch).then((data) => {
                setEarningsReport(data);
            }).catch((e) => {
                dispatch(setNotification({
                    message: 'Failed to get earnings report',
                    type: 'error'
                }));
            });
        }
    }, [credentials, earningsFiscalDateEnding]);

    return (
        <div className="flex h-screen pl-8 pr-8" style={{ height: '85vh' }}>
            {
                (views.includes('earnings') || views.includes('all')) &&
                <EarningsReportComponent setCurrentFiscalDateEnding={setEarningsFiscalDateEnding} earnings={earningsReport} currentFiscalDateEnding={earningsFiscalDateEnding} earningsDates={earningsReportsDates} />
            }
            {
                (views.includes('conversation') || views.includes('all')) &&
                <LlmConvoComponent getCitationTextDetails={getCitationTextDetails} convo={convo} message={message} sendMessage={sendMessage} setMessage={setMessage} />
            }
            {
                (views.includes('transcript') || views.includes('all')) &&
                <TranscriptComponent transcript={transcript} currentFiscalDateEnding={transcriptFiscalDateEnding} setCurrentFiscalDateEnding={setTranscriptFiscalDateEnding} earningsDates={transcriptEarningsDates} />
            }
        </div>
    )
}