import { createSlice } from "@reduxjs/toolkit";

export interface Notification {
    message: string;
    type: 'success' | 'error' | 'info';
}

interface NotificationsReducer {
    currentNotification: Notification | undefined;
}

const notificationSlice = createSlice({
    'name': 'notifications', 
    'initialState': {
        currentNotification: undefined,
    } as NotificationsReducer,
    'reducers': {
        setNotification: (state, action) => {
            state.currentNotification = action.payload;
        }
    }
})

export const { setNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
