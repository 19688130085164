import { PayloadAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer, { UserState } from './user-reducer';
import { thunk } from 'redux-thunk';
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web
import { persistReducer, persistStore } from 'redux-persist';
import axios, { AxiosResponse } from 'axios';
import { refreshTokens } from '../api/thunks/users';
import { SignInResponse } from '../api/models/models';
import notificationsReducer from './notifications-reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'] // only user will be persisted
};

const rootReducer = combineReducers({
  user: userReducer,
  notifications: notificationsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

axios.interceptors.response.use(
  // on success
  (response: AxiosResponse<any, any>) => {
    return response;
  },
  // on error
  async (error) => {
    if (error.response.status === 401) {
      console.log('Refreshing tokens');
      const retryCounter = (parseInt(error.config.headers['X-PQ-Retry-Counter'], 10) || 0);
      if (retryCounter > 3) {
        return Promise.reject(error);
      }
      error.config.headers['X-PQ-Retry-Counter'] = retryCounter + 1;

      const newTokens = await store.dispatch(refreshTokens());
      try {
        const newAccessToken = (newTokens as PayloadAction<SignInResponse>).payload.accessToken;
        // update auth header and retry request
        error.config.headers['Authorization'] = newAccessToken;
        return axios.request(error.config);
      }
      catch (e) {
        console.log('error when retrying request due to token refresh', e);
      }
    }

    return Promise.reject(error);
  });

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
