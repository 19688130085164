import { useNavigate } from "react-router-dom";
import { createUser, signIn } from "../api/thunks/users";
import { useAppDispatch } from "../store/hooks";

export const SignUpComponent = () => {
    document.title = "Sign Up | PocketQuant";
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const name = (e.currentTarget.elements[0] as HTMLInputElement).value;
        const email = (e.currentTarget.elements[1] as HTMLInputElement).value;
        const password = (e.currentTarget.elements[2] as HTMLInputElement).value;
        const confirm_password = (e.currentTarget.elements[3] as HTMLInputElement).value;
        if (password !== confirm_password) {
            alert("Passwords do not match");
            return;
        }

        const passwordValidation = validatePassword(password);
        if (!passwordValidation.valid) {
            alert(passwordValidation.message);
            return;
        }

        const res = await dispatch(createUser({ name, email, password }));

        if (res.meta.requestStatus !== 'fulfilled') {
            alert("Failed to create user");
            return;
        }

        const signInRes = await dispatch(signIn({ email, password }));
        if (signInRes.meta.requestStatus !== 'fulfilled') {
            alert("Sign up succeeded. Failed to sign in. Redirecting to sign in page...");
            navigate('/login');
            return;
        }
        navigate('/dashboard');
    }

    function validatePassword(password: string) {
        // Check for minimum length of 8 characters
        if (password.length < 8) {
            return { valid: false, message: 'Password must be at least 8 characters long.' };
        }

        // Check for at least 1 number
        if (!/\d/.test(password)) {
            return { valid: false, message: 'Password must contain at least one number.' };
        }

        // Check for at least 1 special character
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            return { valid: false, message: 'Password must contain at least one special character.' };
        }

        // Check for at least 1 uppercase letter
        if (!/[A-Z]/.test(password)) {
            return { valid: false, message: 'Password must contain at least one uppercase letter.' };
        }

        // Check for at least 1 lowercase letter
        if (!/[a-z]/.test(password)) {
            return { valid: false, message: 'Password must contain at least one lowercase letter.' };
        }

        return { valid: true, message: 'Password is valid.' };
    }


    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-10 w-auto"
                        src="pq-icon-512.png"
                        alt="PocketQuant Logo"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Create your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>

                            <div className="flex items-center justify-between">
                                <label htmlFor="confirm_password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Confirm Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="confirm_password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <p className="text-sm text-gray-900">
                                Password must be at least 8 characters long and contain at least one number, one special character, one uppercase letter, and one lowercase letter.
                            </p>
                        </div>

                        <div>
                            <div className="flex items-center">
                                <input
                                    id="terms"
                                    name="terms"
                                    type="checkbox"
                                    required
                                    className="h-4 w-4 text-orange-400 border-gray-300 focus:ring-orange-500"
                                />
                                <label htmlFor="terms" className="ml-2 block text-sm leading-5 text-gray-900">
                                    I agree to the <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/terms-of-service');
                                    }} className="font-medium text-orange-400 hover:text-orange-500">terms of service</a> and <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/privacy-policy');
                                    }} className="font-medium text-orange-400 hover:text-orange-500">privacy policy</a>
                                </label>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-orange-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                Sign Up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}